<template>
  <div>
    <h1 class="text-h6 text-md-h4 mb-md-3" :class="fontWeightLightMd">
      Selecciona la entrada y salida
    </h1>

    <!-- Fields component -->
    <v-row>
      <v-col cols="12" md="6">
        <v-dialog
          ref="fromDialog"
          v-model="fromMenu"
          :return-value.sync="fromTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromTime | suffixHrs"
              :label="$t('time.from')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            ampm-in-title
            elevation="15"
            v-if="fromMenu"
            v-model="fromTime"
            :allowed-minutes="allowedStep"
            full-width
            color="identity"
            @click:hour="autoAdjustFrom"
          >
            <v-spacer></v-spacer>
            <v-btn text color="grey" @click="fromMenu = false">
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              text
              color="identity"
              @click="$refs.fromDialog.save(fromTime)"
            >
              {{ $t('general.accept') }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <v-dialog
          ref="toDialog"
          v-model="toMenu"
          :return-value.sync="toTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="toTime | suffixHrs"
              :label="$t('time.to')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            ampm-in-title
            v-if="toMenu"
            v-model="toTime"
            :allowed-minutes="allowedStep"
            full-width
            color="identity"
            @click:hour="autoAdjustTo"
          >
            <v-spacer></v-spacer>
            <v-btn text color="grey" @click="toMenu = false">
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn text color="identity" @click="$refs.toDialog.save(toTime)">
              {{ $t('general.accept') }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <!-- Duration component -->
    <v-sheet max-width="320" class="durationSpacing">
      <v-row justify="center" no-gutters>
        <v-avatar color="" v-if="!validDuration" size="36">
          <span
            ><v-icon :color="durationColor">
              mdi-clock-alert-outline
            </v-icon></span
          >
        </v-avatar>
        <v-avatar v-if="validDuration" size="36">
          <span
            ><v-icon :color="durationColor">
              mdi-check
            </v-icon></span
          >
        </v-avatar>
      </v-row>
      <v-row no-gutters class="mb-16 mb-md-8">
        <v-col cols="2">
          <v-btn
            class="mr-1"
            icon
            color="identity"
            @click="subtract(15, 'm')"
            x-large
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="text-center" align-self="center">
          <v-chip
            class="fix-width"
            :color="durationColor"
            text-color="white"
            chip
            outlined
            large
          >
            <v-chip
              :color="durationColor"
              text-color="white"
              class="fix-width text-subtitle-1"
            >
              Duración {{ resultDuration }}
            </v-chip>
          </v-chip>
        </v-col>
        <v-col cols="2">
          <v-btn icon color="identity" @click="add(15, 'm')" x-large>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
const moment = require('moment')
import { elapsed, stillRemaining } from '@/micro/time/timer'
import { ddFormat, durationOfText, formatted } from '@/micro/time/timeTools'
import { mapGetters, mapActions } from 'vuex'
import { suggestedTime } from '@/micro/time/date'
export default {
  name: 'TimePeriod',
  data() {
    return {
      fromMenu: false,
      toMenu: false
    }
  },

  props: {
    duarationMarginTop: {
      type: String,
      required: false,
      default: 'mt-12'
    }
  },

  computed: {
    ...mapGetters('requests', ['draft']),
    durationSpacing() {
      return `mx-auto ${this.duarationMarginTop}`
    },
    durationColor() {
      return this.validDuration ? 'identity' : 'grey darken-1'
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    fromTime: {
      set: function(val) {
        this.saveDraft({ key: 'fromTime', value: val })
      },
      get: function() {
        return this.draft.fromTime
      }
    },
    toTime: {
      set: function(val) {
        this.saveDraft({ key: 'toTime', value: val })
      },
      get: function() {
        return this.draft.toTime
      }
    },
    duration() {
      return elapsed.ofDurations(this.fromTime, this.toTime)
    },
    resultDuration() {
      const d = this.duration
      return this.durationFormatted(
        moment.duration({ hour: d.hh, minute: d.mm })
      )
    },
    remaining() {
      return stillRemaining(this.duration)
    },
    validDuration() {
      return this.remaining && !!this.fromTime
    },
    checkIcon() {
      return this.validDuration ? 'mdi-check' : ''
    }
  },

  filters: {
    suffixHrs: time => (time ? `${time} hrs` : '')
  },

  methods: {
    ...mapActions('requests', ['saveDraft', 'updateValidSection']),
    autoAdjustFrom() {
      // if (!this.fromTime) {
      //   this.fromTime = 0
      // }
    },
    autoAdjustTo(hours) {
      if (!this.toTime) {
        this.toTime = `${ddFormat(hours)}:00`
      }
    },
    allowedStep: m => m % 15 === 0,
    add(val, unit) {
      if (!this.toTime) this.toTime = this.fromTime
      const d = this.txtToDuration(this.toTime).add(val, unit)
      this.toTime = this.durationFormatted(d)
    },
    subtract(val, unit) {
      if (!this.toTime) this.toTime = this.fromTime
      const d = this.txtToDuration(this.toTime).subtract(val, unit)
      this.toTime = this.durationFormatted(d)
    },
    txtToDuration(hhmm) {
      const d = durationOfText(hhmm)
      return moment.duration({ hour: d.hh, minute: d.mm })
    },
    durationFormatted(d) {
      return formatted(
        'hh:mm',
        { hh: d.hours(), mm: d.minutes() },
        { hhZero: '00' }
      )
    }
  },

  watch: {
    fromTime(newValue) {
      if (
        !this.toTime ||
        !stillRemaining(elapsed.ofDurations(newValue, this.toTime))
      ) {
        this.toTime = newValue
      }
    },
    toTime(newValue) {
      if (!stillRemaining(elapsed.ofDurations(this.fromTime, newValue))) {
        this.fromTime = newValue
      }
    },
    duration(newValue) {
      this.saveDraft({ key: 'duration', value: newValue })
      this.updateValidSection({ section: 'time', value: this.validDuration })
    }
  },

  created() {
    this.fromTime = this.draft.fromTime ? this.draft.fromTime : suggestedTime()
  }
}
</script>

<style lang="scss">
.v-time-picker-clock__ampm .v-picker__title__btn {
  font-weight: bold !important;
  padding: 6px;
  border-radius: 20px;
}
.v-time-picker-clock__ampm .v-picker__title__btn--active {
  background-color: $identity;
  color: white;
}
.v-chip__content {
  font-weight: 700;
}
.fix-width {
  width: 165px;
}
.v-input {
  font-size: 18px;
}
.v-input input {
  max-height: 36px;
  font-size: 20px;
}
</style>
